<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Extracted data</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 py-4 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'pl-5 pr-4': $vuetify.breakpoint.xs }">
        <div class="mt-5 mb-2">
          <h3 class="mb-2">Our API returns a JSON object with:</h3>
          <small>
            <ul>
              <li><b>profile</b>: an object with all the extracted data.</li>
              <li><b>cv_language</b>: the language of the CV.</li>
              <li><b>cv_text</b>: the full text of the CV.</li>
            </ul>
          </small>
        </div>
      </v-flex>
      <v-flex xs12 mt-3 mb-2>
        <v-divider/>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs11>

        <v-layout row wrap mt-6>
          <v-flex xs12>
            <v-btn class="mr-4" outlined @click="selectedTab = 'dataExample'" :class="{'primary white--text': selectedTab === 'dataExample', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-code-json</v-icon>
              Data example
            </v-btn>
            <v-btn class="mr-4" outlined @click="selectedTab = 'jsonSchema'" :class="{'primary white--text': selectedTab === 'jsonSchema', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-code-braces</v-icon>
              JSON schema
            </v-btn>
          </v-flex>
          <v-flex xs12 mt-6 mb-6>
            <small>
              <div>When some profile data is not found in the original CV:</div>
              <ul>
                <li>String value is set to "" by default.</li>
                <li>Integer value is set to null by default.</li>
                <li>Enum value is set to null by default.</li>
              </ul>
            </small>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 v-if="selectedTab === 'jsonSchema'">
            <small :key="reloadKey">
              <returned-data :data="returnedDataSchema" :downloadFileName="'CV_Parser_Premium_Output_Schema.json'"/>
            </small>
          </v-flex>
          <v-flex xs12 v-if="selectedTab === 'dataExample'">
            <small :key="reloadKey">
              <returned-data :data="returnedDataExample" :downloadFileName="'CV_Parser_Premium_Output_Example.json'"/>
            </small>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>

    
  </div>
</template>


<script>
import ReturnedData from '@/components/ReturnedData.vue';

const returnedDataSchema = require('../assets/app/json/returnedDataSchema.json') 
const returnedDataExample = require('../assets/app/json/returnedDataExample.json')
returnedDataExample.profile.professional_experiences[0].end_date = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
}
function calculateDurationInMonths (start_date, end_date) {
  return (end_date.year - start_date.year) * 12 + (end_date.month - start_date.month) + 1;
}
returnedDataExample.profile.professional_experiences[0].duration_in_months = calculateDurationInMonths(returnedDataExample.profile.professional_experiences[0].start_date, returnedDataExample.profile.professional_experiences[0].end_date)

export default {
  components: {
    ReturnedData,
  },
  data: () => ({
    reloadKey: 0,
    returnedDataSchema: returnedDataSchema,
    returnedDataExample: returnedDataExample,
    selectedTab: 'dataExample'
  }),
  watch: {
    selectedTab: {
      handler() {
        this.reloadKey = this.reloadKey + 1
      }
    }
  }
}
</script>