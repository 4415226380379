<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Sign up</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 pb-4 pt-12 mt-12 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'px-4 mb-12 pb-12': $vuetify.breakpoint.xs }">
        <v-layout row wrap justify-center>
          <v-flex xs12 sm6>
            <v-card outlined class="pt-12 pb-4 card-container" :class="{ 'px-12': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xs }" style="border: 2px solid black">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-btn @click="loginOrSignUpWithGoogle" outlined block large>
                    <v-layout align-center>
                      <v-flex shrink>
                        <v-icon>mdi-google</v-icon>
                      </v-flex>
                      <v-flex pr-4>
                        <span>Sign up with Google</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap my-6>
                <v-flex xs5 pt-3>
                  <v-divider class="grey--text"/>
                </v-flex>
                <v-flex text-center>
                  or
                </v-flex>
                <v-flex xs5 pt-3>
                  <v-divider class="grey--text"/>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-form ref="form" @submit.prevent="submitForm">
                    <v-text-field dense label="Your professional email" v-model="email" :rules="emailRules" required outlined class="form-input"></v-text-field>
                    <v-text-field dense label="Password" v-model="password" type="password" :rules="passwordRules" required outlined class="form-input"></v-text-field>
                    <v-btn type="submit" color="primary" block large>Sign up</v-btn>
                  </v-form>
                  <div v-if="errorMessage" class="red--text mt-2">
                    {{ errorMessage }}
                  </div>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 pt-4>
                  <small>
                    By creating an account you accept our <a href="/terms_and_conditions.html" target="_blank">Terms and conditions</a>.
                  </small>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 v-if="$vuetify.breakpoint.smAndUp">
            
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>

    <v-dialog v-model="isLoginOrSignUpPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
const firebase = require('firebase');
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedFile: null,
      hasFileInputBeenTouched: false,

      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || " ",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must be at least 6 characters",
      ],
      errorMessage: null,
      isLoginOrSignUpPending: false,
      emailUsedToSignUp: null
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {
    resetForm() {
      this.$refs.form.resetValidation();
      this.email = '';
      this.password = '';
    },
    submitForm() {
      this.errorMessage = null
      if (!this.$refs.form.validate()) {
        return;
      }
      this.signup();
    },
    signup() {
      this.isLoginOrSignUpPending = true
      this.$auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          // Handle sign up success
          this.emailUsedToSignUp = this.email
          await this.triggerGoogleAndMicrosoftTags()
          const _this = this;
          setTimeout(() => {
            _this.$router.push('/dashboard')
          }, 400)
        })
        .catch(error => {
          // Handle sign up error
          console.error('Sign up error', error);
          this.errorMessage = `An error occurred: ${error.message}`
          this.isLoginOrSignUpPending = false
        });
    },
    loginOrSignUpWithGoogle() {
      this.isLoginOrSignUpPending = true
      const provider = new firebase.auth.GoogleAuthProvider();
      this.$auth
        .signInWithPopup(provider)
        .then(async (result) => {
          this.emailUsedToSignUp = result.user.email
          // Handle LoginOrSignUp with Google success
          // await this.triggerGoogleAndMicrosoftTags()
          const _this = this;
          setTimeout(() => {
            _this.$router.push('/dashboard')
          }, 400)
        })
        .catch(error => {
          // Handle LoginOrSignUp with Google error
          console.error('LoginOrSignUp with Google error', error);
          this.errorMessage = `An error occurred. ${error.message}`
          this.isLoginOrSignUpPending = false
        });
    },
    async triggerGoogleAndMicrosoftTags() {
      try {
        if (
          window.location.hostname === 'cvparser.ai'
          && this.emailUsedToSignUp.indexOf('@gmail.com') === -1
        ) {
          // Google
          this.$gtag.event('conversion', {'send_to': 'AW-11143269683/3rnHCO-RvagZELOaw8Ep'})
          
          // Microsoft
          // window.uetq = window.uetq || [];
          // window.uetq.push('event', 'signup', {});
        }
      } catch(err) {
        console.log('err at triggerGoogleAndMicrosoftTags', err)
      }
    }
  }
};
</script>