import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Home from './views/Home.vue'
import Pricing from './views/Pricing.vue'
import Signup from './views/Signup.vue'
import Login from './views/Login.vue'
import ResetPassword from './views/ResetPassword.vue'

import GetStarted from './views/GetStarted.vue'
import CodeSnippet from './views/CodeSnippet.vue'
import ExtractedData from './views/ExtractedData.vue'

import Dashboard from './views/Dashboard.vue'
import ApiKeys from './views/ApiKeys.vue'
import Subscribe from './views/Subscribe.vue'
import ManageSubscription from './views/ManageSubscription.vue'

import Admin from './views/Admin.vue'

import VueGtag from "vue-gtag";
if (window.location.hostname === 'cvparser.ai') {
  Vue.use(VueGtag, {
    config: {
      id: [
        { params: { id: 'G-NBX72QMMSY' } },
      ]
    }
  });
}


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitForUserToBeLoaded() {
  const user = store.getters.user
  if (user.loggedIn === null) {
    await sleep(100);
    await waitForUserToBeLoaded()
  }
}


const checkIsNotLoggedIn = async (to, from, next) => {
  await waitForUserToBeLoaded()
  const user = store.getters.user
  if (user.loggedIn) {
    if (to.path === '/pricing') {
      if (user.subscription) {
        return next()
      } else {
        return next('/subscribe')
      }
    } else {
      return next('/dashboard')
    }
  } else {
    return next()
  }
}

const checkIsLoggedIn = async (to, from, next) => {
  await waitForUserToBeLoaded()
  const user = store.getters.user
  if (user.data) {
    // Done.
    return next()
  }
  if (user.loggedIn === null) {
    // wait for auth data to be loaded
    return setTimeout(() => checkIsLoggedIn(to, from, next), 100)
  } else if (user.loggedIn) {
    if (!user.data) return setTimeout(() => checkIsLoggedIn(to, from, next), 100)
  } else {
    return next('/')
  }
}

const routes = [
  // Only accessible not logged in
  {
    path: '/',
    component: Home,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/pricing',
    component: Pricing,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/signup',
    component: Signup,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    beforeEnter: checkIsNotLoggedIn,
  },
  
  // Only accessible both logged in and not logged in
  {
    path: '/get-started',
    component: GetStarted
  },
  {
    path: '/code-snippet',
    component: CodeSnippet
  },
  {
    path: '/extracted-data',
    component: ExtractedData
  },

  // Only accessible logged in
  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: checkIsLoggedIn,
  },
  {
    path: '/api-keys',
    component: ApiKeys,
    beforeEnter: checkIsLoggedIn
  },
  {
    path: '/subscribe',
    component: Subscribe,
    beforeEnter: checkIsLoggedIn,
  },
  {
    path: '/manage-subscription',
    component: ManageSubscription,
    beforeEnter: checkIsLoggedIn,
  },
  {
    path: '/admin',
    component: Admin,
    beforeEnter: checkIsLoggedIn,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next();
})

export default router
