<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <v-layout row wrap>
          <v-flex xs12>
            <h2>Log in</h2>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 pb-4 pt-12 mt-12 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'px-4 mb-12 pb-12': $vuetify.breakpoint.xs }">
        <v-layout row wrap justify-center>
          <v-flex xs12 sm6>
            <v-card outlined class="py-12 card-container" :class="{ 'px-12': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xs }" style="border: 2px solid black">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-btn @click="loginOrSignUpWithGoogle" outlined block large>
                    <v-layout align-center>
                      <v-flex shrink>
                        <v-icon>mdi-google</v-icon>
                      </v-flex>
                      <v-flex pr-4>
                        <span>Log in with Google</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap my-6>
                <v-flex xs5 pt-3>
                  <v-divider class="grey--text"/>
                </v-flex>
                <v-flex text-center>
                  or
                </v-flex>
                <v-flex xs5 pt-3>
                  <v-divider class="grey--text"/>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-form ref="form" @submit.prevent="submitForm">
                    <v-text-field dense label="Your professional email" v-model="email" :rules="emailRules" required outlined class="form-input"></v-text-field>
                    <v-text-field dense label="Password" v-model="password" type="password" :rules="passwordRules" required outlined class="form-input"></v-text-field>
                    <v-btn type="submit" color="primary" block large>Log in</v-btn>
                  </v-form>
                  <div v-if="errorMessage" class="red--text mt-2">
                    {{ errorMessage }}
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
            <div class="pl-12 mt-1">
              <small @click="$router.push('/reset-password')" style="cursor: pointer !important" class="ml-1 grey--text text--darken-3">Password forgotten ?</small>
            </div>
          </v-flex>
          <v-flex xs12 sm3 v-if="$vuetify.breakpoint.smAndUp">
            
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isLoginOrSignUpPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
const firebase = require('firebase');

export default {
  data() {
    return {
      selectedFile: null,
      hasFileInputBeenTouched: false,

      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email is not valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required"
      ],
      errorMessage: null,
      isLoginOrSignUpPending: false
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetValidation();
      this.email = '';
      this.password = '';
    },
    submitForm() {
      this.errorMessage = null
      if (!this.$refs.form.validate()) {
        return;
      }
      this.login();
    },
    login() {
      this.isLoginOrSignUpPending = true
      this.$auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          // Handle login success
          this.$router.push('/dashboard')
        })
        .catch(error => {
          // Handle login error
          console.error('Login error', error);
          this.errorMessage = `An error occurred: ${error.message}`
          this.isLoginOrSignUpPending = false
        });
    },
    loginOrSignUpWithGoogle() {
      this.isLoginOrSignUpPending = true
      const provider = new firebase.auth.GoogleAuthProvider();
      this.$auth
        .signInWithPopup(provider)
        .then(() => {
          // Handle LoginOrSignUp with Google success
          this.$router.push('/dashboard')
        })
        .catch(error => {
          // Handle LoginOrSignUp with Google error
          console.error('LoginOrSignUp with Google error', error);
          this.errorMessage = `An error occurred. ${error.message}`
          this.isLoginOrSignUpPending = false
        });
    },
  }

};
</script>