<template>
  <div>
    <v-layout row wrap justify-center>
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex xs12>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left black--text">
                      Plan
                    </th>
                    <th class="text-left black--text" style="min-width:120px">
                      Number of CVs per month
                    </th>
                    <th v-if="user.loggedIn" class="text-left black--text">

                    </th>
                    <th class="text-left black--text" style="min-width:75px">
                      Price per month
                    </th>
                    <th class="text-left black--text" style="min-width:100px">
                      Price per additional CV
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(plan, index) in plans" :key="index">
                    <td><b>{{ plan }}</b></td>
                    <td>{{ baseCVs[index].toLocaleString() }} CVs</td>
                    <td v-if="user.loggedIn">
                      <div v-if="!user.subscription">
                        <v-btn class="primary" :small="$vuetify.breakpoint.xs" @click="startStripeCheckout(priceIds[index])">Subscribe</v-btn>
                      </div>
                      <div v-else-if="user.subscription.items[0].price.id === priceIds[index]">
                        <div v-if="user.subscription.items[0].price.id !== user.data.periods[user.data.periods.length-1].priceId">
                          <v-icon class="mr-1 mb-1" small color="black">mdi-timer-sand</v-icon>
                          Your scheduled plan.
                        </div>
                        <div v-else>
                          <v-icon class="mr-1 mb-1" small color="black">mdi-check</v-icon>
                          Your current plan.
                        </div>
                      </div>
                      <div v-else>
                        <v-btn class="primary" @click="goToUpgradeSubscription(priceIds[index])">Select</v-btn>
                      </div>
                    </td>
                    <td>{{ basePrices[index] }} €</td>
                    <td>{{ additionalPrices[index] }} €</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout row wrap mt-6>
          <v-flex xs12>
            <h3>Find the plan that suits you best</h3>
          </v-flex>
          <v-flex xs10 mt-1 mb-1 text-center>
            <small>
              <b>
                <span v-if="isHoveredCvsAcceptable">
                  Most advantageous plan for {{ displayedHoveredCVs }} CVs: Plan {{ mostAdvantageousPlan }}
                </span>
                <span v-else>
                  Place your mouse over the graph.
                </span>
              </b>
            </small>
          </v-flex>
          <v-flex xs12>
            <svg ref="d3svg" width="1000" height="500"></svg>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isGoToChekoutPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

import * as d3 from "d3";

const prices = require('@/assets/app/data/prices.json')
const priceIdsDev = prices.priceIdsDev
const priceIdsProd = prices.priceIdsProd

const priceIds = {
  'localhost': priceIdsDev,
  'cvparserai-dev.firebaseapp.com': priceIdsDev,
  'cvparserai-prod.firebaseapp.com': priceIdsProd,
  'cvparser.ai': priceIdsProd
}[window.location.hostname]

export default {
  
  data() {
    return {
      isGoToChekoutPending: false,

      // Plans data
      priceIds: priceIds,
      plans: ["A", "B", "C", "D", "E", "F", "G"],
      baseCVs: [1000, 5000, 20000, 35000, 50000, 75000, 100000],
      basePrices: [59, 129, 219, 349, 469, 669, 849],
      additionalPrices: [0.059, 0.0258, 0.01095, 0.00997, 0.00938, 0.00892, 0.00849],
      colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2"],
      svgHeight: 500, // Increased height
      hoveredCVs: 0,
      mostAdvantageousPlan: null,
      isShowingTip: true
    };
  },
  mounted() {
    this.drawChart();
    d3.select(this.$refs.d3svg)
  },
  computed: {
    isHoveredCvsAcceptable() {
      return this.hoveredCVs >= 1 && this.hoveredCVs <= 120000
    },
    displayedHoveredCVs() {
      if (!this.hoveredCVs) return ''
      if (this.hoveredCVs >= 120000) return '120,000+'
      else {
        return this.hoveredCVs.toLocaleString()
      }
    },
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {

    drawChart() {
      const svg = d3.select(this.$refs.d3svg);
      const margin = { top: 20, right: 80, bottom: 60, left: 70 };
      const width = +svg.attr("width") - margin.left - margin.right;
      const height = +svg.attr("height") - margin.top - margin.bottom;
      const x = d3.scaleLinear().rangeRound([0, width]);
      const y = d3.scaleLinear().rangeRound([height, 0]);

      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const line = d3
        .line()
        .x((d) => x(d.CV))
        .y((d) => y(d.cost))
        .defined((d) => d.cost <= 2000); // Define line drawing to stop at 2000€

      // Define the range of CVs and the domain for the scales
      const cvRange = d3.range(0, 120000, 50); // Increased granularity for smoother lines

      x.domain([0, 120000]);
      y.domain([0, 2000]);

      // Draw the X Axis and center the title
      g.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .append("text")
        .attr("fill", "#000")
        .attr("x", width / 2)
        .attr("y", 40)
        .attr("text-anchor", "middle")
        .text("Number of CVs");

      // Draw the Y Axis and adjust the title
      g.append("g")
        .call(d3.axisLeft(y))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("y", 15 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .attr("text-anchor", "middle")
        .text("Cost (€)");

      // Prepare the data for each plan, capping the cost at 2000€
      let planData = this.plans.map((plan, i) => {
        let values = [];
        for (let cv of cvRange) {
          let cost =
            cv <= this.baseCVs[i]
              ? this.basePrices[i]
              : this.basePrices[i] +
                (cv - this.baseCVs[i]) * this.additionalPrices[i];
          if (cost > 2000) {
            // Add one last point at 2000€ and stop
            values.push({ CV: cv, cost: 2000 });
            break;
          }
          values.push({ CV: cv, cost: cost });
        }
        return { name: plan, values: values };
      });

      // Draw the lines for each plan
      planData.forEach((plan, i) => {
        g.append("path")
          .datum(plan.values)
          .attr("class", "line")
          .attr("stroke", this.colors[i])
          .attr("fill", "none")
          .attr("d", line);
      });

      // Create the legend with a smaller font size and cube size
      const legendSquareSize = 10; // Smaller square size for legend
      const legendFontSize = "10px"; // Smaller font size for legend text

      const legend = svg
        .selectAll(".legend")
        .data(this.plans)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", (d, i) => `translate(0,${i * 20})`);

      legend
        .append("rect")
        .attr("x", width + 20)
        .attr("y", margin.top)
        .attr("width", legendSquareSize)
        .attr("height", legendSquareSize)
        .style("fill", (d, i) => this.colors[i]);

      legend
        .append("text")
        .attr("x", width + 35)
        .attr("y", margin.top + legendSquareSize / 2)
        .attr("dy", ".35em")
        .style("text-anchor", "start")
        .style("font-size", legendFontSize)
        .text((d) => "Plan " + d);

      // Add mouseover event listener to SVG
      svg.on("mousemove", (event) => {
        const [mouseX] = d3.pointer(event);
        this.hoveredCVs = Math.round(x.invert(mouseX));
        this.mostAdvantageousPlan = this.getMostAdvantageousPlan(this.hoveredCVs);
      });

      // Create a vertical line to follow the mouse
      const focusLine = g
        .append("line")
        .attr("class", "focus-line")
        .style("stroke", "grey")
        .style("stroke-width", 1.5)
        .style("stroke-dasharray", "3,3")
        .style("opacity", 0)
        .attr("y1", 0)
        .attr("y2", height);

      // Add mouseover event listener to SVG
      svg
        .on("mousemove", (event) => {
          const [mouseX] = d3.pointer(event, g.node());

          this.hoveredCVs = Math.floor(Math.min(Math.round(x.invert(mouseX)), 120000) / 100) *100;
          this.mostAdvantageousPlan = this.getMostAdvantageousPlan(this.hoveredCVs);

          // Update the position of the vertical line
          focusLine
            .attr("transform", `translate(${mouseX},0)`)
            .style("opacity", 1);

          if (!this.isHoveredCvsAcceptable) {
            focusLine.style("opacity", 0);
          }
        })
    },

    getMostAdvantageousPlan(cvCount) {
      // Calculate the most cost-effective plan
      let minCost = Infinity;
      let bestPlan = "";
      for (let i = 0; i < this.plans.length; i++) {
        const cost =
          cvCount <= this.baseCVs[i]
            ? this.basePrices[i]
            : this.basePrices[i] +
              (cvCount - this.baseCVs[i]) * this.additionalPrices[i];
        if (cost < minCost) {
          minCost = cost;
          bestPlan = this.plans[i];
        }
      }
      return bestPlan;
    },


    //// STRIPE
    async startStripeCheckout(priceId) {
      this.isGoToChekoutPending = true

      const docRef = await this.$db
        .collection('users')
        .doc(this.user.data.uid)
        .collection('checkout_sessions')
        .add({
          line_items: [
            {
              price: priceId,
              quantity: 1
            }
          ],
          success_url: window.location.origin + '/dashboard',
          cancel_url: window.location.origin + '/subscribe',
        });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          console.log(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      });
    },


    // UPDATE
    goToUpgradeSubscription(priceId) {
      this.$emit('goToUpgradeSubscription', { priceId })
    }
  },

};
</script>