<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Your API keys</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 py-4 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'pl-5 pr-4': $vuetify.breakpoint.xs }">
        <div class="mt-5 mb-2">
          <h3 class="mb-2">These keys are used to access your CV Parser Premium API.</h3>
          <small>
            <ul>
              <li>Only one key is necessary to make an API call.</li>
              <li>When regenerating a key, you can use the other key for continued access to the service.</li>
              <li>Do not share your keys. Store them securely. We also recommend regenerating these keys regularly.</li>
            </ul>
          </small>
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-4 v-if="user.loggedIn" :class="{ 'pb-12 mb-12': $vuetify.breakpoint.xs }">
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex xs12>
            <v-btn v-if="!areApiKeysRevealed" @click="areApiKeysRevealed=true" outlined>
              <v-icon small class="mr-2">mdi-eye</v-icon>
              Reveal API keys
            </v-btn>
            <v-btn v-else @click="areApiKeysRevealed=false" outlined>
              <v-icon small class="mr-2">mdi-eye-off</v-icon>
              Hide API keys
            </v-btn>
          </v-flex>
          <v-flex xs12 mt-5 pl-1 :class="{ 'mt-12': $vuetify.breakpoint.xs }">
            <div><b>API key 1</b></div>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                <v-text-field :type="areApiKeysRevealed ? '': 'password'" :value="user.data.apiKeys[0]" outlined dense readonly/>
              </v-flex>
              <v-flex xs12 sm8 style="padding-top:1px">
                <v-btn @click="copyApiKey1" class="primary ml-6">
                  <v-icon small class="mr-2">mdi-content-copy</v-icon>
                  Copy
                </v-btn>
                <v-btn @click="apiKeyIndexToRegenerate=0" outlined class="ml-6">
                  <v-icon small class="mr-2">mdi-refresh</v-icon>
                  Regenerate
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pl-1 :class="{ 'mt-12': $vuetify.breakpoint.xs }">
            <div><b>API key 2</b></div>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                <v-text-field :type="areApiKeysRevealed ? '': 'password'" :value="user.data.apiKeys[1]" outlined dense readonly/>
              </v-flex>
              <v-flex xs12 sm8 style="padding-top:1px">
                <v-btn @click="copyApiKey2" class="primary ml-6">
                  <v-icon small class="mr-2">mdi-content-copy</v-icon>
                  Copy
                </v-btn>
                <v-btn @click="apiKeyIndexToRegenerate=1" outlined class="ml-6">
                  <v-icon small class="mr-2">mdi-refresh</v-icon>
                  Regenerate
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isShowingConfirmRegenerateApiKeyDialog" width="50%" persistent>
      <v-card class="pa-12">
        <v-layout row wrap text-center>
          <v-flex xs12>
            <h3>Regenerate API key {{ apiKeyIndexToRegenerate + 1 }} ?</h3>
            <div class="mt-4"><small>Regenerating the API key will return errors to any system using this current API key.</small></div>
          </v-flex>
        </v-layout>
        <v-layout row wrap mt-6>
          <v-spacer/>
          <v-flex xs4 text-center>
            <v-btn class="primary" @click="regenerateApiKey">Regenerate API key {{ apiKeyIndexToRegenerate + 1 }}</v-btn>
          </v-flex>
          <v-flex xs4 text-center>
            <v-btn outlined @click="apiKeyIndexToRegenerate=null">Cancel</v-btn>
          </v-flex>
          <v-spacer/>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isRegenerateApiKeyPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      areApiKeysRevealed: false,
      isShowingConfirmRegenerateApiKeyDialog: false,
      apiKeyIndexToRegenerate: null,
      isRegenerateApiKeyPending: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {
    copyApiKey1() {
      navigator.clipboard.writeText(this.user.data.apiKeys[0]);
    },
    copyApiKey2() {
      navigator.clipboard.writeText(this.user.data.apiKeys[1]);
    },
    async regenerateApiKey() {
      this.isRegenerateApiKeyPending = true
      await this.$functions.httpsCallable('regenerateApiKey')({
        apiKeyIndex: this.apiKeyIndexToRegenerate
      })
      this.apiKeyIndexToRegenerate = null
      setTimeout(() => {
        this.isRegenerateApiKeyPending = false
      }, 1)
    },
  },
  watch: {
    apiKeyIndexToRegenerate: {
      handler(val) {
        this.isShowingConfirmRegenerateApiKeyDialog = (val === 0 || val === 1) ? true : false
      }
    }
  }
}
</script>