<template>
  <v-app>
    <v-layout row wrap>
      <v-flex xs12 sm2 v-if="$vuetify.breakpoint.smAndUp || isShowingResponsiveMainMenu" class="grey lighten-4" :style="$vuetify.breakpoint.smAndUp ? `position: fixed` : ''" :fill-height="$vuetify.breakpoint.smAndUp">
        <v-layout row wrap>
          <v-flex xs12 mt-8 text-center :style="$vuetify.breakpoint.smAndUp ? `font-size:1.3em` : `font-size:2em`">
            <b>CV Parser Premium</b>
          </v-flex>
        </v-layout>

        <v-layout row wrap px-2 :class="{ 'mt-12 pt-1': $vuetify.breakpoint.smAndUp, 'mt-6': !$vuetify.breakpoint.smAndUp, }">
          
          <v-flex xs12 mb-2 v-if="!user.loggedIn">
            <v-btn to="/" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-home</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:5px">
                  <span>Home</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2 v-if="user.loggedIn">
            <v-btn to="/dashboard" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/dashboard' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center style="padding-top:1px; padding-left:4px;">
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:5px">
                  <span>Dashboard</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2 v-if="user.loggedIn">
            <v-btn to="/api-keys" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/api-keys' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-key-variant</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:5px">
                  <span>Your API keys</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2 v-if="user.loggedIn && !user.subscription">
            <v-btn to="/subscribe" block elevation="0" large class="primary">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon x-small>mdi-square</v-icon>
                  <!-- <v-icon small>mdi-crop-square</v-icon> -->
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:1px">
                  <span>Subscribe</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2 v-if="user.loggedIn && user.subscription">
            <v-btn to="/manage-subscription" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/manage-subscription' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <!-- <v-icon x-small>mdi-square</v-icon> -->
                  <v-icon small>mdi-square</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:1px">
                  <span>Update your plan</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 v-if="user.loggedIn" mb-5 :class="{'mt-3': user.subscription, 'mt-5': !user.subscription }">
            <v-layout row wrap justify-center>
              <v-flex xs10>
                <v-divider/>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 mb-2>
            <v-btn to="/get-started" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/get-started' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-play</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:4px">
                  <span>Get started</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>
          
          <v-flex xs12 mb-2>
            <v-btn to="/code-snippet" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/code-snippet' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-code-tags</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:4px">
                  <span>Code snippet</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2>
            <v-btn to="/extracted-data" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/extracted-data' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-grain</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:4px">
                  <span>Extracted data</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs12 mb-2 v-if="!user.loggedIn">
            <v-btn to="/pricing" block elevation="0" large :class="{ 'grey lighten-5': $router.currentRoute.path === '/pricing' }">
              <v-layout row wrap justify-space-between>
                <v-flex xs2 text-center>
                  <v-icon>mdi-cash</v-icon>
                </v-flex>
                <v-flex xs10 text-left pl-4 style="padding-top:4px">
                  <span>Pricing</span>
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

        </v-layout>

        <v-layout row wrap v-if="!user.loggedIn">
          <v-flex xs12 pb-4 :style="$vuetify.breakpoint.smAndUp ? `bottom:0; position: fixed; width: 15%; margin-left:0.75%` : ''" :class="{ 'px-2 mt-6': $vuetify.breakpoint.xs }">
            <v-btn to="/signup" block large class="black white--text" outlined text-center >
              <span>Sign up</span>
            </v-btn>
            <v-btn to="/login" block large class="mt-3 white" outlined text-center style="border: 1px solid black !important">
              <span>Log in</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-layout row wrap v-if="user.loggedIn">
          <v-flex xs12 :class="{ 'text-center': $vuetify.breakpoint.smAndUp, 'ml-12 mt-4 mb-2': $vuetify.breakpoint.xs }" pb-4 :style="$vuetify.breakpoint.smAndUp ? `bottom:0; position: fixed; width: 15%; margin-left:0.75%` : ''">
            <div>
              <small @click="signOut" class="textAsLink">Log out</small>
              <small v-if="user.data">
                - {{ user.data.email }}
              </small>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-spacer/>
      <v-flex xs12 sm10>
        <v-layout row wrap>
          <v-flex xs12 v-if="$vuetify.breakpoint.xs" pt-6 pl-3 mb-2>
            <v-btn v-if="isShowingResponsiveMainMenu" @click="isShowingResponsiveMainMenu=false" class="primary mb-2">
              <v-icon class="mr-2">mdi-close</v-icon>
              <span>Close menu</span>
            </v-btn>
            <v-btn v-else @click="isShowingResponsiveMainMenu=true" class="primary">
              <v-icon class="mr-2">mdi-menu</v-icon>
              <span>Menu</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-main>
          <router-view/>
        </v-main>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      images: {
        logo: require('@/assets/app/images/logo_square.png'),
      },
      isShowingResponsiveMainMenu: false
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {
    signOut() {
      this.$auth.signOut()
        .then(() => this.redirectWhenUserStateIsNotLoggedInAnymore())
    },
    redirectWhenUserStateIsNotLoggedInAnymore() {
      if (this.user.loggedIn) {
        setTimeout(() => this.redirectWhenUserStateIsNotLoggedInAnymore(), 10)
      } else {
        this.$router.push('/')
      }
    },
  }
  
};
</script>


<style>
  .v-btn {
    text-transform: none !important;
  }
  .layout {
    margin: 0 !important;
  }

</style>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

</style>