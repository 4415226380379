<template>
  <div>
    <v-layout row wrap justify-center>
      <v-flex xs10 sm10>
        <v-layout row wrap pt-12 mt-1 pb-6>
          <v-flex xs12 text-center>
            <div v-if="isParsingPending">
              <v-layout row wrap justify-center mt-4>
                <v-flex xs6>
                  <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-flex>
              </v-layout>
            </div>
            <div v-else>
              <v-layout mt-4>
                <v-flex xs12 v-show="!selectedFile">
                  <v-btn outlined class="upload-btn-wrapper" :class="{'mt-2': !parsedData}">
                    <!-- <v-icon class="mr-2" color="#ffffffeb">mdi-file-upload</v-icon> -->
                    <span v-if="parsedData">Upload another CV</span>
                    <span v-else>Upload a CV</span>
                    <input ref="fileInput" type="file" @change="handleFileUpload"/>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>

        <v-layout row wrap mb-12 v-if="isParsingPending || parsedData || isParsingError">
          <v-flex xs12 text-center v-if="parsedData">
            <div class="mb-2"><small>Time spent: {{ timeToDisplay }} s</small></div>
            <v-btn @click="downloadParsedDataAsText" class="primary">
              <v-icon small style="margin-top:2px">mdi-download</v-icon>
              <span class="ml-3">Download extracted data</span>
            </v-btn>
            <div class="mt-12 pt-6">
              <small>
                Or
                <span style="cursor: pointer; text-decoration: underline;" @click="downloadParsedData">download it as a .json file</span>
              </small>
            </div>
          </v-flex>
          <v-flex xs12 text-center v-if="isParsingPending">
            <small>
              <div class="mt-6">
                <b>Your CV is being parsed thoroughly.</b>
              </div>
              <div>Time spent: {{ timeToDisplay }} s</div>
              <div>
                It usually takes around 15 seconds.
              </div>
              <!-- <div v-if="timeToDisplay > 40" class="mt-3">
                A long CV takes more time to be parsed.
              </div> -->
              <div class="mt-10">
                By making multiple API calls in parallel,<br>you'll be able to parse up to 500 CVs per minute.<br><br>Contact us if you have this type of high volumes.
              </div>
            </small> 
          </v-flex>
          <v-flex xs12 text-center v-if="isParsingError">
            <small>An error occured with the uploaded CV.</small> 
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";

const functionsUrlBase = {
  'localhost': 'http://localhost:5001/cvparserai-dev/us-central1',
  'cvparserai-dev.firebaseapp.com': 'https://europe-west1-cvparserai-dev.cloudfunctions.net',
  'cvparserai-prod.firebaseapp.com': 'https://europe-west1-cvparserai-prod.cloudfunctions.net',
  'cvparser.ai': 'https://europe-west1-cvparserai-prod.cloudfunctions.net'
}[window.location.hostname]

const TEST_API_KEY = {
  'localhost': 'ce568d14d189da0eb4724aaafe67f7b4',
  'cvparserai-dev.firebaseapp.com': 'ce568d14d189da0eb4724aaafe67f7b4',
  'cvparserai-prod.firebaseapp.com': 'be219edcd614914db6ada9750e3addf3',
  'cvparser.ai': 'be219edcd614914db6ada9750e3addf3'
}[window.location.hostname]

export default {
  data() {
    return {
      selectedFile: null,
      hasFileInputBeenTouched: false,
      isParsingPending: false,
      isParsingSuccess: false,
      parsedData: null,
      isParsingError: false,
      timer: null,
      lastParsingStartedAt: null,
      timeToDisplay: null
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {
    handleFileUpload(event) {
      this.hasFileInputBeenTouched = true;
      this.selectedFile = event.target.files[0] || null;

      // Check if a file is selected
      if (this.selectedFile) {
        const reader = new FileReader();

        // Read the file as a Data URL
        reader.readAsDataURL(this.selectedFile);

        // Wait for the file to be loaded
        reader.onload = async () => {
          // Extract the base64 data from the Data URL
          const base64Source = reader.result.split(',')[1];

          // const url = `${functionsUrlBase}/api_demo/v3/parse`;
          const url = `${functionsUrlBase}/api_demo/v4/parse`;
          const headers = {
            'Content-Type': 'application/json',
            'X-API-Key': TEST_API_KEY,
            'Is-From-Interface': true,
            'Is-Logging-Enabled': true
            // 'Is-Debug': true,
          };
          const data = {
            base64: base64Source
          };

          this.lastParsingStartedAt = Date.now()
          this.timer = setInterval(this.updateTime, 100);
          this.isParsingPending = true
          this.isParsingSuccess = false
          this.parsedData = null
          this.isParsingError = false
          try {
            const response = await axios.post(url, data, { headers })
            this.isParsingSuccess = true
            this.parsedData = response.data.data
            this.isParsingError = false
            this.isParsingPending = false
          } catch (error) {
            console.error(error);
            this.isParsingSuccess = false
            this.isParsingError = true
            this.isParsingPending = false
          } finally {
            this.clearFileInput()
            clearInterval(this.timer);
          }
        };
      }
    },
    updateTime() {
      if (this.isParsingPending) {
        this.timeToDisplay = Math.floor( (Date.now() - this.lastParsingStartedAt) / 1000)
      }
    },
    clearFileInput() {
      this.selectedFile = null
    },
    downloadParsedData() {
      const data = JSON.stringify(this.parsedData);
      const blob = new Blob([data], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CV_parsed_by_CV_Parser_Premium.json');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadParsedDataAsText() {
      const textData = JSON.stringify(this.parsedData, null, 2); // Adds indentation to the JSON text
      const blob = new Blob([textData], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CV_parsed_by_CV_Parser_Premium.txt');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }
}
</script>

<style scoped>


  .upload-btn-wrapper {
    overflow: hidden;
    border-color: white;
    color:white
  }

  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: -20px;
    top: -20px;
    opacity: 0;
  }

  .primary.v-btn--active, .primary.v-btn--active:hover {
    background-color: #1976D2 !important;
  }

  .primary.v-btn--active .v-icon, .primary.v-btn--active:hover .v-icon {
    color: #ffffff !important; 
  }

</style>