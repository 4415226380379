<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Code snippet</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 py-4 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'pl-5 pr-4': $vuetify.breakpoint.xs }">
        <div class="mt-5 mb-2">
          <h3 class="mb-2">You can either send a URL pointing to the CV, or directly send the CV in the request.</h3>
          <small>
            <ul>
              <li>
                <b>Choose the way that is the easiest for you to set up.</b>
              </li>
              <li>
                The code examples below use a .pdf file but you can use any of the following formats: .pdf, .docx, .doc, .odt, .pptx, .ppt, .jpeg, .png.
              </li>
              <li>
                If you are using another programming language, feel free to ask ChatGPT to convert the cURL command into your used language.
              </li>
            </ul>
          </small>
        </div>
      </v-flex>
      <v-flex xs12 mt-3 mb-2>
        <v-divider/>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs11>
        <h3 class="mt-8">Sending a URL pointing to the CV</h3>
        <div class="mt-2 mb-4">
          <small>
            <div>Make sure that the url points to a CV file that is accessible for our server to download.</div>
            <div v-if="isShowingMoreExplanationsFromUrl">
              <div>Provide a public url or a presigned url.</div>
              <div>To verify that your URL is accessible, try to open it in an incognito window.</div>
              <div>Url must be a pointing to a file (ex: .pdf), not a web page displaying the file.</div>
              <h3 class="mt-3">Examples</h3>
              <h4 class="mt-2">AWS S3:</h4>
              <ul>
                <li>Url example if your file is publicly accessible:<br>https://s3.amazonaws.com/example_bucket/your_cv.pdf</li>
                <li class="mt-1">
                  <div>If your file is not publicly accessible, use a presigned URL. Presigned url example:<br>https://s3.amazonaws.com/example_bucket/your_cv.pdf?X-Amz-Algorithm=example&X-Amz-Credential=example&X-Amz-Date=example&X-Amz-Expires=example&X-Amz-SignedHeaders=example&X-Amz-Signature=example</div>
                </li>
              </ul>
              <h4 class="mt-2">Google Docs:</h4>
              <ul>
                <li>The sharing permission should be "Anyone with the link".</li>
                <li class="mt-1">Url example: https://docs.google.com/document/d/{document_id}/export?format=pdf</li>
              </ul>
              <h4 class="mt-2">Google Drive:</h4>
              <ul>
                <li>The sharing permission should be "Anyone with the link".</li>
                <li class="mt-1">Url example: https://drive.google.com/uc?export=download&id={document_id}</li>
              </ul>
            </div>
            <div v-if="!isShowingMoreExplanationsFromUrl">
              <span @click="isShowingMoreExplanationsFromUrl = true" class="textAsLink">See more</span>
            </div>
            <div class="mt-2" v-if="isShowingMoreExplanationsFromUrl">
              <span @click="isShowingMoreExplanationsFromUrl = false" class="textAsLink">See less</span>
            </div>
          </small>
        </div>
        <v-layout row wrap>
          <v-flex xs12>
            <v-btn class="mr-4" outlined @click="selectedLanguage = 'bash'" :class="{'primary white--text': selectedLanguage === 'bash', 'mt-2': $vuetify.breakpoint.xs }">
              <span class="mr-1">://</span>
              cURL
            </v-btn>
            <v-btn class="mr-4" outlined @click="selectedLanguage = 'python'" :class="{'primary white--text': selectedLanguage === 'python', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-language-python</v-icon>
              Python
            </v-btn>
            <v-btn outlined @click="selectedLanguage = 'nodejs'" :class="{'primary white--text': selectedLanguage === 'nodejs', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-nodejs</v-icon>
              Node.js
            </v-btn>
          </v-flex>
        </v-layout>
        <code-sample :selectedLanguage="selectedLanguage" :isFromUrl="true" />
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center mt-4>
      <v-flex xs11>
        <h3 class="mt-6">Sending the CV as base64 inside the request</h3>
        <div class="mt-2 mb-4">
          <small>Limited to 10MB.</small>
        </div>
        <v-layout row wrap>
          <v-flex xs12>
            <v-btn class="mr-4" outlined @click="selectedLanguage = 'bash'" :class="{'primary white--text': selectedLanguage === 'bash', 'mt-2': $vuetify.breakpoint.xs }">
              <span class="mr-1">://</span>
              cURL
            </v-btn>
            <v-btn class="mr-4" outlined @click="selectedLanguage = 'python'" :class="{'primary white--text': selectedLanguage === 'python', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-language-python</v-icon>
              Python
            </v-btn>
            <v-btn outlined @click="selectedLanguage = 'nodejs'" :class="{'primary white--text': selectedLanguage === 'nodejs', 'mt-2': $vuetify.breakpoint.xs }">
              <v-icon class="mr-2">mdi-nodejs</v-icon>
              Node.js
            </v-btn>
          </v-flex>
        </v-layout>
        <code-sample :selectedLanguage="selectedLanguage" :isFromUrl="false" />
      </v-flex>
    </v-layout>



  </div>
</template>

<script>
import CodeSample from "@/components/CodeSample.vue";

export default {
  components: {
    CodeSample,
  },
  data: () => ({
    selectedLanguage: 'bash',
    isShowingMoreExplanationsFromUrl: false
  })
};
</script>

<style scoped>
.v-tooltip__content {
  background-color: #37474f !important;
  opacity: 1 !important;
}
</style>