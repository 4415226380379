<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm8 :class="{ 'pr-4': $vuetify.breakpoint.xs }">

        <v-layout row wrap>
          <v-flex xs12 py-4 pl-12>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                <h2>Dashboard</h2>
              </v-flex>
              <v-flex xs12 sm8 text-end pr-12>
                <div v-if="!$vuetify.breakpoint.xs">
                  <v-chip outlined color="primary" href="https://matching.hr" target="_blank">
                    <div><b>Your goal is to build a matching algorithm? Use <u>Matching.HR</u></b></div>
                  </v-chip>
                </div>
                <div v-if="$vuetify.breakpoint.xs">
                  <div>
                    <v-chip outlined color="primary" href="https://matching.hr" target="_blank">
                      <div><b>Your goal is to build a matching algorithm?</b></div>
                    </v-chip>
                  </div>
                  <div class="mt-3">
                    <v-chip outlined color="primary" href="https://matching.hr" target="_blank">
                      <div><b>Use <u>Matching.HR</u></b></div>
                    </v-chip>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12>
            <v-divider/>
          </v-flex>

          <v-flex xs12 py-4 pl-12 v-if="!user.subscription">
            <div class="mt-5 mb-2">
              <h3 class="mb-2">What you need to do</h3>
              <small>
                <b>
                  <div class="mb-3">
                    <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-1-box-outline</v-icon>
                    <span>Copy-paste the <span @click="$router.push('/code-snippet')" class="textAsLink">code snippet</span> to call our API.</span>
                  </div>
                  <div class="mb-3">
                    <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-2-box-outline</v-icon>
                    <span><span @click="$router.push('/api-keys')" class="textAsLink">Copy your API key</span> to insert in the code snippet.</span>
                  </div>
                  <div class="mb-3">
                    <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-3-box-outline</v-icon>
                    <span>Use your free credits to test parsing CVs with the API.</span>
                  </div>
                  <div class="mb-3">
                    <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-4-box-outline</v-icon>
                    <span>In your code, map the extracted data fields to your own data structure.</span>
                  </div>
                  <div class="mb-3">
                    <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-5-box-outline</v-icon>
                    <span><span @click="$router.push('/subscribe')" class="textAsLink">Get your subscription</span></span>
                  </div>
                </b>
              </small>
            </div>
          </v-flex>

          <v-flex xs12 py-4 pl-12 v-if="!user.subscription">
            <div>
              <h3 class="mb-2">Free trial</h3>
              <small>
                <div v-if="!user.subscription">
                  <div v-if="!user.data.uid || (user.data.trialApiCreditsNb > 0)">
                    <div><b>You can still parse {{ user.data.trialApiCreditsNb || '' }} {{ user.data.trialApiCreditsNb === 1 ? 'CV' : 'CVs' }} for free with the API.</b></div>
                    <!-- <div class="">When subscribed, you will here find the number of CVs parsed during each monthly cycle.</div> -->
                  </div>
                  <div v-else class="mt-2">
                    <b>
                      <div>You have used all your free credits.</div>
                      <div><span @click="$router.push('/subscribe')" class="textAsLink">Subscribe</span> to keep parsing CVs.</div>
                      <div>You won't have to make any change to your code.</div>
                    </b>
                  </div>
                </div>
              </small>
            </div>
          </v-flex>

          <v-flex xs12 py-2 text-center v-if="!user.subscription" :class="{ 'pr-10': $vuetify.breakpoint.smAndUp, 'mb-12 mt-2': $vuetify.breakpoint.xs }">
            <v-btn outlined large class="primary white--text" to="/subscribe">
              Choose your subscription
            </v-btn>
          </v-flex>

          <v-flex xs12 mt-5 py-4 pl-12>
            <div v-if="user.subscription && userProductAndPriceId.product">
              <v-layout row wrap>
                <v-flex xs12 :class="{ 'pr-12': $vuetify.breakpoint.smAndUp, '': $vuetify.breakpoint.xs }">
                  <h3 class="mb-2">Usage</h3>
                  <usage-history />
                </v-flex>
              </v-layout>
              <v-layout row wrap mt-10>
                <v-flex xs12>
                  <h3 class="mb-2">Your subscription</h3>
                  <div>
                    <small>
                      <div>{{ userProductAndPriceId.product.name }}.</div>
                      <div>{{ userProductAndPriceId.product.description }}</div>

                      <div v-if="!user.subscription.cancel_at_period_end && user.subscription.items[0].price.id !== userProductAndPriceId.priceId" class="mb-2 mt-4">
                        <div><b>Your downgrade will be efficient at the end of the current monthly cycle.</b></div>
                        <div><b>Future downgraded plan:</b></div>
                        <div>{{user.subscription.items[0].price.product.name}}</div>
                        <div>{{user.subscription.items[0].price.product.description}}</div>
                      </div>

                      <div v-if="user.subscription.cancel_at_period_end" class="red--text mt-3 mb-3">
                        <div>Your subscription is planned to be cancelled on {{ displayDate(user.subscription.current_period_end.seconds) }}.</div>
                        <div @click="cancelSubscriptionCancellation" class="textAsLink">Un-cancel your subscription</div>
                      </div>
                      <div v-else>
                        <div class="my-4">
                          <div :class="{ 'red--text': user.subscription.status === 'past_due' }">Subscription status: {{user.subscription.status}}.</div>
                          <div v-if="user.subscription.status === 'active'">Renewal date: {{ displayDate(user.subscription.current_period_end.seconds) }}</div>
                          <div v-if="user.subscription.status === 'past_due'" class="red--text">
                            <div>
                              <b>Your latest payment has failed. Your API access is about to be disabled.</b>
                            </div>
                            <div>
                              <b><span class="textAsLink" @click="openCustomerPortal">Update your payment method and pay your pending invoice now</span></b>
                            </div>
                          </div>
                        </div>
                        <div>
                          To upgrade, downgrade or cancel your subscription, <span class="textAsLink" @click="$router.push('/manage-subscription')">click here</span>.
                        </div>
                      </div>
                      <div>
                        To see invoice history, update payment method or update billing information, <span class="textAsLink" @click="openCustomerPortal">click here</span>.
                      </div>
                    </small>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout row wrap mt-11 :class="{ 'mb-12': $vuetify.breakpoint.xs }">
                <v-flex xs12>
                  <h3 class="mb-1">Contact</h3>
                  <div>
                    <small>
                      Feel free to send us an email at <a class="textAsLink" href="mailto:contact@cvparser.ai">contact@cvparser.ai</a>
                    </small>
                  </div>
                </v-flex>
              </v-layout>
            </div>

            <v-layout row wrap>
              <v-flex xs12 style="margin-top:24px" :class="{'white--text': $vuetify.breakpoint.xs}">
                <div style="position: absolute; bottom:17px">
                  <small>
                    <div>
                      <span>&copy; Copyright {{ new Date().getFullYear() }} – CV Parser Premium – GDPR compliant.</span>
                      <br v-if="$vuetify.breakpoint.xs" />
                      <a class="ml-2" href="/terms_and_conditions.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Terms and conditions</a> – 
                      <a class="" href="/privacy_policy.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Privacy policy</a> – 
                      <a class="" href="/cookie_policy.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Cookie policy</a>.
                    </div>
                  </small>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

        </v-layout>
      </v-flex>
      <v-flex xs12 sm4 style="background-color:black; min-height:100vh">
        <v-layout row wrap class="grey--text text--lighten-4" pt-12>
          <v-flex xs12 text-center pt-12 mt-2>
            <h1 style="font-size:3.1em;">Try it</h1>
          </v-flex>
          <v-flex xs12>
            <try-it-out-public/>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isOpenCustomerPortalPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isCancelSubscriptionCancellationPending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import TryItOutPublic from '@/components/TryItOutPublic.vue';
import UsageHistory from '@/components/UsageHistory.vue';

export default {
  components: {
    TryItOutPublic,
    UsageHistory
  },
  data() {
    return {
      isOpenCustomerPortalPending: false,
      isCancelSubscriptionCancellationPending: false,
      products: []
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    userHasSubscription() {
      return !!this.user.subscription
    },
    customerPortalLink() {
      return `${this.stripeCustomerPortalBaseUrl}?prefilled_email=${encodeURI(this.user.data.email)}`
    },
    userProductAndPriceId() {
      try {
        const priceId = this.user.data.periods[this.user.data.periods.length-1].priceId
        const product = this.products.find(product => product.prices.find(price => price.id === priceId))
        return {
          product,
          priceId
        }
      } catch {
        return {
          product: null,
          priceId: null
        }
      }
    }
  },
  mounted() {
    this.setStripeProducts()
  },
  methods: {
    async openCustomerPortal() {
      this.isOpenCustomerPortalPending = true
      const { data } = await this.$deployedFunctions.httpsCallable('ext-firestore-stripe-payments-createPortalLink')({ returnUrl: window.location.origin + '/dashboard' });
      window.location.assign(data.url);
    },
    async cancelSubscriptionCancellation() {
      this.isCancelSubscriptionCancellationPending = true
      await this.$functions.httpsCallable('cancelSubscriptionCancellation')()
      const _this = this;
      setTimeout(() => {
        _this.isCancelSubscriptionCancellationPending = false
      }, 2000)
    },

    numberWithCommaStr(number) {
      return `${number}`.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    displayDate(dateAsSeconds, joinerStr) {
      const date = new Date(dateAsSeconds * 1000);

      const optionsDate = { month: 'long', day: '2-digit', year: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };

      const formattedDate = date.toLocaleDateString('en-US', optionsDate).replace(',', '');
      const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

      return `${formattedDate}${joinerStr || ' at '}${formattedTime}`
    },

    async setStripeProducts() {
      const productsQuerySnapshot = await this.$db.collection('stripe_products').where('active', '==', true).get()
      this.products = (await Promise.all(
        productsQuerySnapshot.docs.map(productSnap => {
          return new Promise(resolve => {
            const product = productSnap.data()
            product.id = productSnap.id
            productSnap.ref.collection('prices').where('active', '==', true).get().then(pricesQuerySnapshot => {
              product.prices = pricesQuerySnapshot.docs.map(priceSnap => {
                const price = priceSnap.data()
                price.id = priceSnap.id
                return price
              })
              return resolve(product)
            })
          })
        })
      ))
    }
  },
}
</script>
