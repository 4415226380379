<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Subscribe</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider />
      </v-flex>
      <v-flex xs12 py-4 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'pl-5 pr-4': $vuetify.breakpoint.xs }">
        <div class="mt-5 mb-2">
          <h3 class="mb-2">Plans for everyone, with volume discounts</h3>
          <small>
            <ul>
              <li>Plans are monthly subscriptions.</li>
              <li>
                No commitment. You can cancel/downgrade/upgrade at any time.
              </li>
              <li>
                Each plan lets you parse up to a certain number of CVs per
                month.
              </li>
              <li>
                If you exceed this number, you are billed for each additional CV
                parsed.
              </li>
            </ul>
          </small>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12>
        <prices/>
      </v-flex>
    </v-layout>
  </div>
</template>




<script>
import { mapGetters } from "vuex";

import Prices from '@/components/Prices.vue';

export default {
  components: {
    Prices
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  mounted() {
    if (this.user.loggedIn && !!this.user.subscription) {
      this.$router.push('/manage-subscription')
    }
  },
  methods: {

  },

}
</script>