<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <v-layout row wrap>
          <v-flex xs12>
            <h2>Reset password</h2>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 pb-4 pt-12 mt-12 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'px-4 mb-12 pb-12': $vuetify.breakpoint.xs }">
        <v-layout row wrap justify-center>
          <v-flex xs12 sm6>
            <v-card outlined class="py-12 card-container" :class="{ 'px-12': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xs }" style="border: 2px solid black">
              <v-layout row wrap>
                <v-flex xs12>
                  <div v-if="!success">
                    <v-form ref="form" @submit.prevent="sendEmail" v-model="isFormValid">
                      <v-layout wrap justify-center>
                        <v-flex xs12 class="text-center" mt-2>
                          <v-text-field dense v-model="email" outlined type="email" autofocus label="Your professional email address" :rules="[emailRules]" :disabled="isPending"/>
                        </v-flex>
                        <v-flex xs12 text-left v-if="!isPending" text-center>
                          <small>We will send you an email with a link to reset your password.</small>
                        </v-flex>
                        <v-flex xs12 class="text-center mt-6">
                          <v-progress-linear v-if="isPending" indeterminate color="primary"></v-progress-linear>
                          <v-btn v-else type="submit" class="primary" block large :disabled="isPending">Send email</v-btn>
                          <div v-if="errorMessage" class="red--text mt-5">{{ errorMessage }}</div>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </div>
                  <div v-else>
                    <div>
                      <v-layout row wrap>
                        <v-flex xs12 text-center>
                          <div><v-icon color="black">mdi-check</v-icon></div>
                          <small>
                            <div class="my-2">
                              <b>Check your inbox for a link to reset your password.</b>
                            </div>
                            <div>
                              We sent it to {{ email }}
                            </div>
                          </small>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 v-if="$vuetify.breakpoint.smAndUp">
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

export default {
  data: () => ({
    email: null,
    isFormValid: false,
    isPending: false,
    success: false,
    errorMessage: null
  }),
  methods: {
    emailRules(v) {
      if (!v) return ' '
      if (!/.+@.+\..+/.test(v)) return ' '
      return true
    },
    async sendEmail() {
      if (!this.isFormValid) return;
      this.errorMessage = null
      this.isPending = true
      const _this = this
      this.$auth.sendPasswordResetEmail(this.email)
        .then(function () {
          _this.success = true
          _this.isPending = false
        })
        .catch(function (error) {
          _this.isPending = false
          _this.errorMessage = error.message
        });
    }
  }
}
</script>