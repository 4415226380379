<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12 pt-4>
        <v-btn @click="copyCode" class="primary">
          <v-icon small class="mr-2">mdi-content-copy</v-icon>
          Copy code
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap mt-1>
      <v-flex xs12>
        <vue-code-highlight :language="highlightLanguage" :key="reloadKey">
          <pre>
            {{ code }}
          </pre>
        </vue-code-highlight>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from 'vue-code-highlight';
import "vue-code-highlight/themes/prism-tomorrow.css";
import "vue-code-highlight/themes/window.css";
import 'prism-es6/components/prism-markup-templating';
import 'prism-es6/components/prism-python';
import 'prism-es6/components/prism-bash';


export default {
  components:{
    VueCodeHighlight,
  },
  props: {
    isFromUrl: Boolean,
    selectedLanguage: String
  },
  data() {
    return {
      reloadKey: 0,

      bashCodeFromUrl: `
curl -X POST 'https://cvparser.ai/api/v4/parse' \\
  -H 'Content-Type: application/json' \\
  -H 'X-API-Key: YOUR_API_KEY' \\
  -d '{
    "url": "https://example.com/your_cv_file.pdf"
  }'`,

      pythonCodeFromUrl: `
import requests

url = 'https://cvparser.ai/api/v4/parse'
headers = {
    'Content-Type': 'application/json',
    'X-API-Key': 'YOUR_API_KEY'
}
data = {
    'url': 'https://example.com/your_cv_file.pdf'
}

response = requests.post(url, headers=headers, json=data)
print(response.json())`,

      nodejsCodeFromUrl: `
const axios = require('axios');

const url = 'https://cvparser.ai/api/v4/parse';
const headers = {
  'Content-Type': 'application/json',
  'X-API-Key': 'YOUR_API_KEY'
};
const data = {
  url: 'https://example.com/your_cv_file.pdf'
};

axios.post(url, data, { headers })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error(error);
  });`,

      bashCodeFromBase64: `
curl -X POST 'https://cvparser.ai/api/v4/parse' \\
  -H 'Content-Type: application/json' \\
  -H 'X-API-Key: YOUR_API_KEY' \\
  -d '{
    "base64": "'$(cat ./your_cv_file.pdf | base64)'"
  }'`,

      pythonCodeFromBase64: `
import requests
import base64

url = 'https://cvparser.ai/api/v4/parse'
headers = {
    'Content-Type': 'application/json',
    'X-API-Key': 'YOUR_API_KEY'
}

with open('./your_cv_file.pdf', 'rb') as file:
    encoded_file = base64.b64encode(file.read()).decode('utf-8')

data = {
    'base64': encoded_file
}

response = requests.post(url, headers=headers, json=data)
print(response.json())`,

      nodejsCodeFromBase64: `
const axios = require('axios');
const fs = require('fs');

const url = 'https://cvparser.ai/api/v4/parse';
const headers = {
  'Content-Type': 'application/json',
  'X-API-Key': 'YOUR_API_KEY'
};

const base64 = fs.readFileSync('./your_cv_file.pdf').toString('base64')
const data = {
  base64
};

axios.post(url, data, { headers })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error(error);
  });`,
    };
  },
  computed: {
    code() {
      if (this.isFromUrl) {
        return {
          bash: this.bashCodeFromUrl,
          python: this.pythonCodeFromUrl,
          nodejs: this.nodejsCodeFromUrl,
        }[this.selectedLanguage];
      } else {
        return {
          bash: this.bashCodeFromBase64,
          python: this.pythonCodeFromBase64,
          nodejs: this.nodejsCodeFromBase64,
        }[this.selectedLanguage];
      }
    },
    highlightLanguage() {
      return {
        bash: 'bash',
        python: 'python',
        nodejs: 'javascript',
      }[this.selectedLanguage];
    },
  },
  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.code.trim());
    },
  },
  watch: {
    selectedLanguage: {
      handler() {
        this.reloadKey = this.reloadKey + 1
      }
    }
  }
};
</script>
