<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              From
            </th>
            <th class="text-left">
              To
            </th>
            <th class="text-left">
              Plan
            </th>
            <th class="text-left">
              Usage
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(period, key) in sortedPeriods" :key="key">
            <td>{{ (new Date(period.start.seconds * 1000)).toLocaleDateString(undefined, { month: 'long', day: '2-digit', year: 'numeric' })}}</td>
            <td>{{ (new Date(period.end.seconds * 1000)).toLocaleDateString(undefined, { month: 'long', day: '2-digit', year: 'numeric' })}}</td>
            <td>{{ getProductNameFromPriceId(period.priceId) }}</td>
            <td>{{ period.consumedCreditsNb }} CVs parsed</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      products: []
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    sortedPeriods() {
      try {
        return JSON.parse(JSON.stringify(this.user.data.periods)).sort((a,b) => b.start.seconds*1000 - a.start.seconds*1000)
      } catch(err) {
        return []
      }
    }
  },
  mounted() {
    this.setStripeProducts()
  },
  methods: {
    async setStripeProducts() {
      const productsQuerySnapshot = await this.$db.collection('stripe_products').where('active', '==', true).get()
      this.products = (await Promise.all(
        productsQuerySnapshot.docs.map(productSnap => {
          return new Promise(resolve => {
            const product = productSnap.data()
            product.id = productSnap.id
            productSnap.ref.collection('prices').where('active', '==', true).get().then(pricesQuerySnapshot => {
              product.prices = pricesQuerySnapshot.docs.map(priceSnap => {
                const price = priceSnap.data()
                price.id = priceSnap.id
                return price
              })
              return resolve(product)
            })
          })
        })
      ))
    },
    getProductNameFromPriceId(priceId) {
      const product = this.products.find(product => product.prices.find(price => price.id === priceId))
      if (product) return product.name
      else return ''
    }
  }

}
</script>