<template>
  <div>
    <v-layout row wrap v-if="users.length > 0">
      <v-flex xs12 py-4 pl-12>
        <h2>Admin</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-4 v-if="users.length > 0">
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex xs12>
            <v-checkbox v-model="showOnlyUsersWithAtLeastOnePeriod" label="Has or had subscription" />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-divider/>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-for="(user, key) in displayedUsers" :key="key">
          <v-flex xs12 my-8>
            <v-card class="py-6 px-6">
              <v-layout row wrap>
                <v-flex xs8>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <div><b>{{ user.email }}</b></div>
                      <small>
                        <div><i>{{ user.id }}</i></div>
                        <div>Created at {{ displayDate(user.createdAt/1000) }}</div>
                      </small>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap v-if="user.periods && user.periods.length > 0">
                    <v-flex xs12>
                      <admin-usage-history :user="user"/>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4 text-end>
                  <v-btn @click="impersonate(user.id)">Impersonate</v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>


    <v-dialog v-model="isImpersonatePending" width="50%" persistent>
      <v-card class="pa-6">
        <v-progress-linear indeterminate color="primary" class="mt-3"></v-progress-linear>
      </v-card>
    </v-dialog>
   
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import AdminUsageHistory from '@/components/AdminUsageHistory.vue';

export default {
  components: {
    AdminUsageHistory
  },
  data() {
    return {
      users: [],
      isImpersonatePending: false,
      showOnlyUsersWithAtLeastOnePeriod: true
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    displayedUsers() {
      return this.users.filter(user => {
        if (this.showOnlyUsersWithAtLeastOnePeriod && (!user.periods || user.periods.length === 0)) return false
        return true
      })
    }
  },
  async mounted() {
    this.users = JSON.parse(JSON.stringify((await this.$functions.httpsCallable('adminListUsers')()).data)).sort((a,b) => b.createdAt - a.createdAt)
  },
  methods: {
    async impersonate(userId) {
      this.isImpersonatePending = true
      const token = (await this.$functions.httpsCallable('adminImpersonate')({
        userId
      })).data
      await this.$auth.signInWithCustomToken(token)
      this.$router.go()
    },

    displayDate(dateAsSeconds, joinerStr) {
      const date = new Date(dateAsSeconds * 1000);

      const optionsDate = { month: 'long', day: '2-digit', year: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };

      const formattedDate = date.toLocaleDateString('en-US', optionsDate).replace(',', '');
      const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

      return `${formattedDate}${joinerStr || ' at '}${formattedTime}`
    }
  },

}
</script>