<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-btn @click="copy" outlined>
          <v-icon small class="mr-2">mdi-content-copy</v-icon>
          Copy
        </v-btn>
        <v-btn @click="download" outlined class="ml-4">
          <v-icon small class="mr-2">mdi-download</v-icon>
          Download
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap mt-2>
      <v-flex xs12>
        <vue-code-highlight language="javascript">
          <pre>
{{ data }}
          </pre>
        </vue-code-highlight>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from 'vue-code-highlight';
import "vue-code-highlight/themes/prism-tomorrow.css";
import "vue-code-highlight/themes/window.css";
import 'prism-es6/components/prism-markup-templating';
import 'prism-es6/components/prism-python';
import 'prism-es6/components/prism-bash';


export default {
  components:{
    VueCodeHighlight,
  },
  props: {
    data: Object,
    downloadFileName: String
  },
  methods: {
    download() {
      const data = JSON.stringify(this.data);
      const blob = new Blob([data], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.downloadFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    copy() {
      navigator.clipboard.writeText(JSON.stringify(this.data));
    },
  },
};
</script>
