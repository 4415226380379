<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Update your plan</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
      <v-flex xs12 py-4 :class="{ 'pl-12': $vuetify.breakpoint.smAndUp, 'pl-5 pr-4': $vuetify.breakpoint.xs }">
        <v-layout row wrap>
          <v-flex xs12 mt-5>
            <h3>Choose another plan</h3>
            <small>
              <div class="mt-1">
                <div>The way upgrading and downgrading works has been made to benefit you.</div>
                <div class="mt-3"><u>In short:</u></div>
                <div>When choosing a higher plan, your subscription is instantly updated.</div>
                <div>When choosing a lower plan, your subscription is updated at the next monthly cycle.</div>
                <div>In both cases, monthly renewal date is not affected.</div>
              </div>
              <div class="mt-5 mb-2"><u>In depth:</u></div>
              <div>
                <h4 class="mb-0">Upgrading</h4>
                <ul>
                  <li><b>If you see that you are going to exceed your quota in the current monthly cycle, you can now already switch to a more advantageous higher subscription.</b></li>
                  <li><b>Both the current cycle and the next ones will be billed accordingly to the new chosen plan.</b></li>
                  <li><b>This is made in order to limit your potential over-quota usage costs that are charged at the end of the current cycle.</b></li>
                  <li>You will today be charged the difference between your existing plan and the new chosen one.</li>
                  <li>The monthly renewal date won't be affected, which means that your next monthly cycle will start on {{ displayDate(user.subscription.current_period_end.seconds) }}.</li>
                </ul>
              </div>
              <div class="mt-3">
                <h4 class="mb-0">Downgrading</h4>
                <ul>
                  <li><b>If you see that you are consuming less than expected, you can schedule a downgrade for the next monthly cycle.</b></li>
                  <li>This is made so that you can keep benefitting from the current higher plan that you have paid until the end of the current cycle.</li>
                  <li>The monthly renewal date won't be affected, which means that your next monthly cycle will start on {{ displayDate(user.subscription.current_period_end.seconds) }}.</li>
                </ul>
              </div>
            </small>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap pt-6>
      <v-flex xs12>
        <prices @goToUpgradeSubscription="showUpgradeSubscription"/>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <v-layout row wrap>
          <v-flex xs12 mt-5 mb-2 :class="{ 'pr-12': $vuetify.breakpoint.smAndUp, '': $vuetify.breakpoint.xs }">
            <h3 class="mb-2">Your usage history</h3>
            <usage-history />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <v-layout row wrap>

          <v-flex xs12 mt-5 mb-2>
            <h3 class="mb-2">Cancel</h3>
            <div>
              <small>
                <div>If you choose to cancel your subscription, it will still be active until the end of the current monthly cycle: {{ displayDate(user.subscription.current_period_end.seconds) }}.</div>
                <div><span class="textAsLink" @click="isShowingCancelSubscriptionDialog = true">Cancel your subscription</span></div>
              </small>
            </div>
          </v-flex>

          <v-flex xs12 mt-12 mb-2>
            <h3 class="mb-2">Contact</h3>
            <div>
              <small>
                <div>For any question, feel free to reach out at <a href="mailto:contact@cvparser.ai" class="textAsLink">contact@cvparser.ai</a></div>
              </small>
            </div>
          </v-flex>


        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isShowingCancelSubscriptionDialog" width="50%" :persistent="isCancelSubscriptionPending">
      <v-card class="py-12 px-6" v-if="!isCancelSubscriptionPending">
        <v-layout row wrap text-center>
          <v-flex xs12>
            <h3>Cancel your subscription ?</h3>
            <div class="mt-4">
              <small>
                <div>The CV parsing API will systematically return errors to your https requests starting on {{ displayDate(user.subscription.current_period_end.seconds) }}.</div>
                <div>Make sure that your production code won't be affected.</div>
              </small>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap mt-6>
          <v-spacer/>
          <v-flex xs4 text-center>
            <v-btn small @click="cancelSubscription" class="red--text">Cancel subscription</v-btn>
          </v-flex>
          <v-flex xs4 text-center>
            <v-btn small outlined @click="isShowingCancelSubscriptionDialog=false">I don't want to cancel</v-btn>
          </v-flex>
          <v-spacer/>
        </v-layout>
      </v-card>
      <v-card class="pa-6" v-if="isCancelSubscriptionPending">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isShowingUpdateSubscriptionDialog" width="50%" :persistent="isUpdateSubscriptionPending">
      <v-card class="pb-12 pt-10 px-12" v-if="!isUpdateSubscriptionPending">
        
        <div v-if="!isDowngradeNotUpgrade">
          <v-layout row wrap>
            <v-flex xs12>
              <h3 class="text-center">Upgrade your subscription</h3>
              <div class="mt-4 ">
                <small>
                  <div>Your subscription will be upgraded right now.</div>
                  <div><b>You will now be charged {{ selectedPriceEuros - currentPriceEuros }}€.</b> This the difference between the existing price ({{ currentPriceEuros }}€) and the new one ({{ selectedPriceEuros }}€).</div>
                  <div><b>You will then be charged {{ selectedPriceEuros }}€ on {{ displayDate(user.subscription.current_period_end.seconds) }}</b>, renewed every month, without commitment.</div>
                </small>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap mt-6>
            <v-spacer/>
            <v-flex xs4 text-center>
              <v-btn small class="primary" @click="upgradeSubscription">Upgrade subscription</v-btn>
            </v-flex>
            <v-flex xs4 text-center>
              <v-btn small outlined @click="isShowingUpdateSubscriptionDialog=false">Close</v-btn>
            </v-flex>
            <v-spacer/>
          </v-layout>
        </div>

        <div v-if="isDowngradeNotUpgrade">
          <v-layout row wrap>
            <v-flex xs12>
              <h3 class="text-center">Downgrade your subscription</h3>
              <div class="mt-4 ">
                <small>
                  <div>Your subscription will be downgraded at the next monthly cycle.</div>
                  <div><b>Starting {{ displayDate(user.subscription.current_period_end.seconds) }} you will be charged {{ selectedPriceEuros }}€ per month</b>, renewed every month, without commitment.</div>
                </small>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap mt-6>
            <v-spacer/>
            <v-flex xs4 text-center>
              <v-btn small class="primary" @click="downgradeSubscription">Downgrade subscription</v-btn>
            </v-flex>
            <v-flex xs4 text-center>
              <v-btn small outlined @click="isShowingUpdateSubscriptionDialog=false">Close</v-btn>
            </v-flex>
            <v-spacer/>
          </v-layout>
        </div>

        
      </v-card>
      <v-card class="pa-6" v-if="isUpdateSubscriptionPending">
        <v-progress-linear indeterminate color="black"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Prices from '@/components/Prices.vue';
import UsageHistory from '@/components/UsageHistory.vue';


// IMPORTANT: prices in prices.json must be sorted from cheapest to higher price, for isDowngradeNotUpgrade to work.
const prices = require('@/assets/app/data/prices.json')
const basePrices = [59, 129, 219, 349, 469, 669, 849]
const priceIdsDev = prices.priceIdsDev
const priceIdsProd = prices.priceIdsProd

const priceIds = {
  'localhost': priceIdsDev,
  'cvparserai-dev.firebaseapp.com': priceIdsDev,
  'cvparserai-prod.firebaseapp.com': priceIdsProd,
  'cvparser.ai': priceIdsProd
}[window.location.hostname]

export default {
  components: {
    Prices,
    UsageHistory
  },
  data() {
    return {
      selectedPriceId: null,

      isShowingUpdateSubscriptionDialog: false,
      isUpdateSubscriptionPending: false,

      isShowingCancelSubscriptionDialog: false,
      isCancelSubscriptionPending: false
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    isDowngradeNotUpgrade() {
      return priceIds.indexOf(this.selectedPriceId) < priceIds.indexOf(this.user.subscription.items[0].price.id)
    },
    currentPriceEuros() {
      return basePrices[priceIds.indexOf(this.user.subscription.items[0].price.id)]
    },
    selectedPriceEuros() {
      return basePrices[priceIds.indexOf(this.selectedPriceId)]
    }
  },
  mounted() {
    if (this.user.subscription.cancel_at_period_end) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    showUpgradeSubscription(data) {
      this.selectedPriceId = data.priceId
      this.isShowingUpdateSubscriptionDialog = true
    },
    async upgradeSubscription() {
      this.isUpdateSubscriptionPending = true
      await this.$functions.httpsCallable('upgradeSubscription')({ newPriceId: this.selectedPriceId })
      this.$router.push('/dashboard')
    },
    async downgradeSubscription() {
      this.isUpdateSubscriptionPending = true
      await this.$functions.httpsCallable('downgradeSubscription')({ newPriceId: this.selectedPriceId })
      this.$router.push('/dashboard')
    },
    async cancelSubscription() {
      this.isCancelSubscriptionPending = true
      await this.$functions.httpsCallable('cancelSubscription')()
      this.$router.push('/dashboard')
    },
    displayDate(dateAsSeconds, joinerStr) {
      const date = new Date(dateAsSeconds * 1000);

      const optionsDate = { month: 'long', day: '2-digit', year: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };

      const formattedDate = date.toLocaleDateString('en-US', optionsDate).replace(',', '');
      const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

      return `${formattedDate}${joinerStr || ' at '}${formattedTime}`
    }
  }

}
</script>